.inputField {
  color: #757575 !important;
  font-size: 12px;

  & input,
  textarea {
    padding: 1rem !important;
    color: #757575 !important;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #424242 !important;
  }

  & label {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.006em;
    color: #424242;
    z-index: 1;
    background: #fff;
    margin: -3px;
  }
}

.options {
  padding: 8px 24px;
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  color: #424242;
}

.optiontext {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  color: #424242;
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.dropDown {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  color: #424242;
  background: #ffffff;
  border-color: #616161 !important;
  border-width: 0.8px !important;
  border-radius: 10px;
  margin: 1;
  width: 385;
}

.inputLabel {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.006em;

  /* Neutral/90 */

  color: #424242;
}

.select {
}
