//NEW DESIGNS

.signWrapper {
	display: flex;
	height: 100vh;
}

.leftwrapper {
	flex: 1;
	padding: 30px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	/* Firefox */
}

.leftwrapper::-webkit-scrollbar {
	display: none;
}

.rightwrapper {
	flex: 1;
}

.rightwrapper_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: left;
	object-fit: cover;
}

.image {
	margin-bottom: 10px;
	img {
		width: 150px;
	}
}

.formwrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 106px;
}

.form_heading {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #272501;
	margin-bottom: 10px;
}

.form_p {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #858481;
}

.businessForm {
	padding: 0 140px;
	width: 100%;
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.signInButton {
	// max-width: 441px;
	width: 100%;
	height: 56px;
	background: #19943c;
	border-radius: 4px;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 4px;
	cursor: pointer;
	border: none;
	margin: 0 auto;
	margin-top: 36px;
}

.signInButton:disabled {
	opacity: .5;
}

.forgetPassword {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	color: #272501;
}

.createuser {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #272501;
	margin-top: 35px;
}

.forgetPasswordspan {
	color: #19943c;
	cursor: pointer;
}

.passwordInp {
	margin: 20px 0;
	position: relative;
	width: 100%;
}

.inputImg {
	cursor: pointer;
	position: absolute;
	width: 20px;
	right: 30px;
	top: 35px;
}

.fasignWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 0.5px solid rgb(200, 199, 199);
	border-radius: 8px;
	width: 700px;
	height: auto;
	margin: 70px auto;
}

.falogo {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	padding: 10px;
}

.faformwrapper {
	width: 80%;
}

.faform_p {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	color: #000000;
	margin: 30px 0px;
}

.fabusinessForm {
	width: 100%;
}

.faform {
	width: 100%;
}

.fasignInButton {
	width: 100%;
	height: 56px;
	background: #19943c;
	border-radius: 4px;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 4px;
	border: none;
	margin: 0 auto;
	margin-top: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.facreateuser {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #000000;
	margin: 30px 0;
}

@media only screen and (max-width: 1100px) {
	.signinForm {
		margin: 0 auto;
	}

	.rightwrapper {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	.signinForm {
		margin: 0 auto;
		// width: 350px;
	}

	.businessForm {
		padding: 0 40px;
	}
}

@media only screen and (max-width: 600px) {
	.businessForm {
		padding: 0 10px;
	}
}