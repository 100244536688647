.selection {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #6f6e56;
	cursor: pointer;
}

.selectionActive {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #080803;
	cursor: pointer;
}

.wrapper {
	display: flex;
}

.wrapper_first {
}

.wrapper_second {
	flex: 1;
	margin-left: 115px;
}
