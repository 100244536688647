.filterSubheading {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.filterBody {
	margin-top: 10px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.modalwrapper {
	width: 518px;
	// height: 574px;
	background: #ffffff;
}
.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
}
.modalheadh3 {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}
.modalcontent {
	padding: 34px 32px;
	width: 100%;
}
.modalcontentp {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #272501;
}
.modalcontentbox {
	margin-top: 29px;
	padding: 17px 30px;
	background: #f5faf7;
	border-radius: 5px;
	margin-bottom: 60px;
}
.modalcontentboxflex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.modalcontentboxp {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #272501;
}
.modalselect {
	width: 100%;
	margin-bottom: 21px;
}
.select {
	width: 100%;
}
.inputLabel {
}
.modalFooter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 16px;
}
.cancel {
	width: 84px;
	height: 40px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #19943c;
	margin-right: 16px;
	outline: none;
	border: 1px solid rgb(238, 238, 238);
	background: #ffffff;
	border-radius: 4px;
}
.fund {
	padding: 11px 24px;
	height: 40px;
	background: #19943c;
	border-radius: 4px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	outline: none;
	border: none;
}

.input {
	width: 100%;
	margin-bottom: 29px;
}

.modalupload {
	height: 315px;
	background: #ffffff;
	border: 1px solid #2b9558;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	button {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #19943c;
		width: 160px;
		height: 40px;
		border: 1px solid #19943c;
		border-radius: 4px;
		margin-top: 50px;
		background-color: #ffffff;
	}

	p {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #4b492b;
		margin: 0;
		padding: 0;
		margin-top: 15px;
	}
}

.modaluploadp {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #070602;
	margin-top: 28px;
	display: flex;
	justify-content: flex-start;
}

.sampleDownload {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: green;
	cursor: pointer;
}
