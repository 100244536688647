.topTable {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.detailsHeader {
	background: #ededed;
	border-radius: 8px 8px 0px 0px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	padding: 10px 20px;
}

.tableContent {
	background: #ffffff;
	padding-left: 20px;
	border-radius: 0px 0px 8px 8px;
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
}

.customerInfo {
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 50%;
	justify-content: space-between;
	padding: 14px 0px;
}

.detailsValue {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.detailsKey {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.transactionsHeader {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 10px;
}

.detailBox {
	background: white;
	margin-bottom: 24px;
}

.detailh3 {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #0a0903;
	padding: 14px 24px;
}

.customerInfo {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 50%;
	justify-content: flex-start;
	padding: 34px 0px;
}

.detailsValue {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #5c5b57;
}

.detailsKey {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}
