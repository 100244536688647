.buttonwrapper {
	display: flex;
	justify-content: end;
	align-items: center;
	margin-top: 30px;
	padding: 0 20px;
}

.resetFilter {
	padding: 7px 24px;
	background: #ffffff;
	border-radius: 4px;
	color: #19943c;
	outline: none;
	border: none;
}

.applyFilter {
	padding: 7px 24px;
	border-radius: 4px;
	background: #19943c;
	color: #ffffff;
	margin-left: 10px;
	outline: none;
	border: none;
}
