.selection {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #d4d3cc;
	cursor: pointer;
	margin: 0 32px;
}

.selectionActive {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #19943c;
	cursor: pointer;
	margin: 0 32px;
	text-decoration: underline;
	text-underline-offset: 16px;
	text-decoration-color: #19943c;
	text-decoration-thickness: 2px;
}

.tabWrapper {
	display: flex;
	align-items: center;
	height: 56px;
	background: white;
	border-radius: 8px;
	margin-bottom: 24px;
}
.selectionwrapper {
	background: white;
	border-radius: 8px;
}

.selectflex {
	display: flex;
	align-items: flex-start;
}

.selectflex_p {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	color: #211f01;
	// padding-right: 108px;
	padding-left: 27px;
	padding-top: 37px;
}
