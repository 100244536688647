.balance {
	margin-top: 16px;
	.balanceCardTwo {
		margin-top: 16px;
	}
}

.dashboardChart {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	height: 100%;
	padding: 24px;
}
.scrolwrapper {
	overflow-x: scroll;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.scrolwrapper::-webkit-scrollbar {
	display: none;
}

.scrolwrapper {
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
}
