.wrapper {
	padding: 36px 40px;
	width: 100%;
}

.wrapperInner {
	display: flex;
	width: 100%;
}

.wrapperInnerLeft {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #e7f3ec;
	border-radius: 14px;
	margin-right: 29px;
	width: 63px;
	height: 26px;
	cursor: pointer;

	p {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		color: #272501;
		margin: 0;
		padding: 0;
		margin-left: 10px;
	}
}

.wrapperInnerRight {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.wrapperh2 {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #19943c;
}

.wrapperp {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #4b492b;
	margin-bottom: 35px;
	width: 567px;
}

.reusebox {
	height: 83px;
	background: #ffffff;
	border-radius: 7px;
	padding: 18px 27px;
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
}


.reuseboxsecond {
	flex: 1;
	margin: 0 19px;

	h3 {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #020201;
	}

	p {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #4b492b;
		margin: 0;
		padding: 0;
	}
}

.reuseboxthird {
	display: flex;
	justify-content: flex-end;
}

.resend {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	color: #19943c;
	cursor: pointer;
}

.buttonsect {
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.button {
	padding: 12px 24px;
	width: 175px;
	height: 48px;
	background: #19943c;
	border-radius: 4px;
	outline: none;
	border: none;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	cursor: pointer;

	&:disabled {
		opacity: .5;
		cursor: not-allowed;
	}
}

.buttonp {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #4b492b;
	margin-top: 19px;
}