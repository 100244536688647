.table {
	background: #ffffff;
	border: 1px solid #f3f4f4;
	box-sizing: border-box;
	box-shadow: 0px 0px 1px rgba(177, 182, 183, 0.5);
	border-radius: 4px;
	margin-top: 9px;
	border-radius: 8px 8px 0px 0px;
	font-family: 'Sarabun';
}


.tableHead {
	background: #e1f0fc;
	padding: 9.5px 20px;
	border: 1px solid #ededed;
	box-sizing: border-box;
	box-shadow: 0px 0px 1px rgba(177, 182, 183, 0.5);
	background: #ededed;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	color: #0a0a0a;
}

.tableBody {
	padding-top: 28px 20px;
	cursor: pointer;
	// border-bottom: 1px solid #e5e5e5;
	font-family: 'Sarabun';
}

.tableBodyHover {
	padding-top: 28px 20px;
	cursor: pointer;

	&:hover {
		background-color: #edfaf1;
	}
}

.newStaff {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
}

.addStaff {
	width: 200px;
	background: #011a4b;
	border: 1px solid #ecf0f4;
	box-sizing: border-box;
	border-radius: 4px;
	font-family: Steradian;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #d0eaff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 8px;
}

.filter {
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;

	/* Input/Active/Placeholder */
	color: #b5b5bd;
	padding-top: 5px;
	padding-bottom: 5px;
	width: 100px;
	margin-left: 11px;
}

.paginatedSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background: #ffffff;
	border-radius: 0px 0px 8px 8px;
	padding: 10px;
	color: #858481;
}

.previous {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	color: #a79b9b;
	padding-left: 12px;
}

.previousTrue {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	color: #424242;
	padding-left: 12px;
}

.next {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	padding-right: 12px;
	padding-left: 28px;
}

.nextFalse {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	padding-right: 12px;
	padding-left: 28px;
	color: #a79b9b;
}

.pageNumber {
	font-family: Steradian;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;
	color: #37474f;
}

.arrowDirections {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 24px;
}

.emptyState {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 250px;
	background: #f8f8f8;
}

.noTransaction {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	color: #0a0a0a;
}

.pageIndex {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	color: #424242;
}

.dataTotal {
	font-family: 'Sarabun';
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	color: #424242;
}

.selection {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.006em;
	margin-left: 10px;
	padding: 4px;
	color: #0a0a0a;
	border: 0.8px solid #9e9e9e;
	box-sizing: border-box;
	border-radius: 4px;
}

.pageleft {
	display: flex;
	align-items: center;
	color: #33322d;
	font-family: 'Sarabun';
	font-weight: 500;
	font-size: 16px;
}

.inputBox {
	width: 41px;
	height: 32px;
	background: #ffffff;
	border: 1px solid #cececd;
	border-radius: 4px;
	vertical-align: middle;
	text-align: center;
	outline: none;
}

.inputBox::-webkit-inner-spin-button,
.inputBox::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

@media only screen and (max-width: 900px) {
	table {
		display: block;
		overflow-x: auto;
		white-space: nowrap;
	}

	.table_wrapper {
		display: table;
		width: 100%;
	}
}
