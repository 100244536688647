.wrapper {
	width: 250px;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: none;
}

.wrappercover {
	width: 99.9%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	padding-bottom: 100px;
}

.changeCurrencyWrapper {
	display: flex;
	padding: 0 20px;
	margin-bottom: 20px
}

.dropwrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-left: 24px;
	margin-top: 8px;
	margin-bottom: 15px;
	padding-right: 32px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 2000;
	background-color: #ffffff;
	padding: 8px 0;
}

.dropdown {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.dropdownimg {
	width: 150px;
	// height: 40px;
	object-fit: contain;
}

.dropdownspan {
	display: block;
	color: black;
	margin-left: 12px;
	font-weight: 700;
}

.dropdownspancoll {
	display: none;
	color: black;
	margin-left: 12px;
	font-weight: 700;
}

.dropdownIcon {
	margin-left: 12px;
	margin-top: 5px;
	// animation: bounce 3s infinite;
}

.getstarted {
	margin-left: 22px;
	margin-bottom: 16px;
	margin-top: 16px;
}

.getstartedlink {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #4b492b;

	&:hover {
		color: #4b492b;
	}
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-30px);
	}

	60% {
		transform: translateY(-15px);
	}
}

.subwrapper {
	padding: 12px;
	background: #85d69c;
	border: 1px solid #edf2ef;
	border-radius: 8px;
	// height: 68px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.subwrappercontent {
	flex: 1;

	h2 {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		color: #012711;
		padding: 0;
		margin: 0;
	}

	p {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: #5c6b63;
		padding: 0;
		margin: 0;
	}
}

.newBusinesses {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 12px;

	.businessName {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
		cursor: pointer;
		padding-left: 16px;
	}
}

.addNew {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	cursor: pointer;
}

.newBusiness {
	padding-left: 12px;
}