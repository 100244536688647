.signInBody {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
	flex-direction: column;
	height: 100vh;
}

.logo {
	img {
		width: 150px;
	}
}

.signinForm {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	width: 507px;
	margin-top: 40px;
	padding: 24px;

	.businessForm {
		width: 100% !important;
		margin-top: 20px;
		margin-right: auto;

		&>form {
			display: flex;
			flex-direction: column;

			&>div {
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
		}
	}

	.form {
		width: 100%;
	}

	.signIn {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
		text-align: center;
	}
}

.signInButton {
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 100%;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 12.5px 0px;
	cursor: pointer;

	& span {
		padding-left: 10px;
	}
}

.forgotPassword {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #19943c;
	margin-top: 20px;
	cursor: pointer;
}

.newAccount {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin-top: 30px;

	.noAccount {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #424242;
	}

	.create {
		background: #19943c;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		height: 40px;
		padding: 5px 10px;
		margin-left: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		cursor: pointer;

		& span {
			padding-left: 10px;
		}
	}
}

.sidebarFooter {
	position: fixed;
	bottom: 0;
	left: 0;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: -0.006em;
	color: #424242;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
}

.createlink {
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	height: 50px;
	padding: 10px 15px;
	margin-left: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	cursor: pointer;
	outline: none;
	border: none;
	margin-top: 24px;
}

.createlinkTest {
	background-color: transparent !important;
	color: #19943c;
	border: 1px solid #19943c;

}

@media only screen and (max-width: 1200px) and (min-width: 601px) {
	.signinForm {
		width: 80vw;
	}
}

@media (max-width: 600px) {
	.signinForm {
		width: 80vw;
	}

	.newAccount {
		display: block;
		text-align: center;
	}
}