.routerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #f8f8f8;
}

.switchContainer {
	margin: 0 40px;
	overflow-y: scroll;
}

.navContainer {
}

.wrapper {
	display: flex;
	position: relative;
	overflow-y: hidden;
	height: 100vh;
	width: 100%;
}

.sidedrawer {
	width: 300px;
	height: 100vh;
	border-right: 1px solid rgba(206, 206, 205, 0.4);
	overflow: hidden;
}
.sidedrawerColl {
	width: 78px;
	transition: all 250ms ease-in-out;
	overflow: hidden;
}

.tabContainer {
	margin: 32px 40px 24px 40px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 74px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.activeMenuItem {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	background-color: #fff;
	margin: 0 1rem;

	.activeItemColor {
		color: #0a0903;
		border-bottom: 3.5px solid #19943c;
		border-radius: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
	}
}
.subMenuItem {
	padding: 0.5rem;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #424242;
	margin-bottom: 5px;
}

.subMenuItem:hover {
	background: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}
.site-layout {
	background: #fafafa;
}

.site-layout .site-layout-background-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fafafa;
	height: 48px;
	width: 100%;
	border: 1px solid rgba(46, 91, 255, 0.08);
	padding: 0 33px;
}

@media screen and (max-width: 640px) {
	.site-layout .site-layout-background-header {
		padding: 0 10px;
	}
}
@media screen and (max-width: 440px) {
	.ant-layout .ant-layout-header {
		padding: 0 10px;
	}
}

.site-layout .site-layout-background-content {
	background: #fafafa;
}

.scrollbarhide::-webkit-scrollbar {
	display: none;
}

.scrollbarhide {
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
}

// @media only screen and (max-width: 920px) {
// 	.switchContainer {
// 		margin-top: 1rem;
// 	}
// }
