.wrapper {
  padding: 36px 40px;
  width: 100%;
}
.wrapperInner {
  display: flex;
  width: 100%;
}
.wrapperInnerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7f3ec;
  border-radius: 14px;
  margin-right: 29px;
  width: 94px;
  height: 26px;
  cursor: pointer;

  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #272501;
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }
}
.wrapperInnerRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapperh2 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #19943c;
}
.wrapperp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4b492b;
  margin-bottom: 35px;
  width: 567px;
}
//STOPS
.inputwrapper {
  padding: 25px 33px;
  width: 539px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 35px;
}
.submitHandler {
  width: 84px;
  height: 40px;
  background: #19943c;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: #57dac493;
  }
}
.flexInput {
  width: 100%;
  display: flex;
  align-items: center;
}

.input {
  height: 54px;
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
}
.inputText {
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
}

.buttonflex {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cancel {
  width: 84px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  outline: none;
  border: 1px solid #19943c;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #19943c;
  margin-right: 11px;
}

.formH3 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #070602;
}

.imagelogo {
  width: 94px;
  height: 94px;
  border-radius: 4px;
  object-fit: cover;
}

.logowrapper {
  display: flex;
  margin: 28px 0;

  img {
    margin-right: 11px;
  }
  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4b492b;
  }
}

.preview {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}
