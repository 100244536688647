// .wrapper {
// 	padding: '0 40px';
// }

// @media only screen and (min-width: 1500px) {
// 	.wrapper {
// 		padding: 0 10px 0 40px;
// 	}
// }

.notificationTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.messageFrom {
		font-size: 17px;
		line-height: 24px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 700;
	}

	.date {
		font-size: 15px;
		line-height: 24px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		color: #767373;
	}
}

.messageText {
	font-size: 15px;
	line-height: 24px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	color: #767373;
}

.switchStatement {
	display: flex;
	justify-content: center;
	align-items: center;
}

.switchText {
	font-size: 12px;
	line-height: 24px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	color: #767373;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
}

.navigationBack {
	display: flex;
	align-items: center;
}