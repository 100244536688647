.preferencesBody {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	overflow-y: scroll;
	margin-top: 8px;
}

.preferencesBody {
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
}

.preferencesBody::-webkit-scrollbar {
	display: none;
}

.accounts {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;
	width: 100%;
	.accountInfo {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
	.accountEarnings {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #616161;
		margin-top: 4px;
	}
}

.paymentMethods {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;
	width: 100%;
	margin-top: 30px;
	.accountInfo {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
	.accountEarnings {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #616161;
		margin-top: 4px;
	}
}

.radioSelection {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 5px;
}
.radioLabel {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
}

.radioSelection_one {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 25px;
	padding-bottom: 10px;
	border-bottom: 1px solid #e0e0e0;
	.radioLabel {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
}

.paymentList {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 30px;
	.checkboxContent {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
	}
	.checkboxContentFa {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
	}
	.checkboxContent:nth-child(n + 1):nth-child(-n + 3) {
		border-bottom: 1px solid #e0e0e0;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.checkboxLabel {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
}

.business {
	width: 507px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow-y: scroll;
	margin: 8px auto;
}

.businessCard {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;
	.logoText {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.imagePlaceholder {
		text-align: center;
		height: 100px;
		width: 100px;
		margin-top: 8px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin: 8px auto 0 auto;
	}
}

.businessForm {
	width: 100% !important;
	margin-top: 20px;
	margin-right: auto;
	& > form {
		display: flex;
		flex-direction: column;
		& > div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}
.form {
	width: 100%;
}

.uploadButton {
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	cursor: pointer;
	& span {
		padding-left: 8px;
	}
}

.deliveryButton {
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	width: 100%;
	cursor: pointer;
	& span {
		padding-left: 8px;
	}
}
.accountEarnings {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
	margin-top: 4px;
}

.newProduct {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
	.text {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
	.save {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 12px;
		width: 88px;
		height: 40px;
		background: #19943c;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		cursor: pointer;
		outline: none;
		border: none;

		&:disabled {
			background: #65776d;
		}
	}
}

.inputLabel {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.006em;

	/* Neutral/90 */

	color: #424242;
}

.businessFormModal {
	width: 100% !important;
	// margin-top: 24px;
	margin-right: auto;
	padding: 0 24px;
	& > form {
		display: flex;
		flex-direction: column;
		padding-top: 24px;
		padding-bottom: 24px;
		height: 360px;
		overflow-y: scroll;
		& > div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}
.feeEntry {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 12px;
	.feeEntryName {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
}

.transactionsHeader {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 10px;
	display: flex;
	flex-direction: row;
}

.productImg {
	border-radius: 8px;
	width: 80px;
	height: 80px;
}

@media screen and (max-width: 1250px) {
	.flexwrapper {
		flex-direction: column;
	}
}
