.webhookUrl {
	width: 475px;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;
	margin-bottom: 30px;
	margin-right: 20px;
	.urlName {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
	.urlContent {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
		padding-bottom: 8px;
		margin-top: 24px;
		border-bottom: 1px solid #e0e0e0;
	}
	.copy {
		background: #ffffff;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		color: #19943c;
		cursor: pointer;
		width: 55px;
		height: 20px;
		border: 0.5px solid #57dac493;
		border-radius: 100px;
		margin-top: 24px;
	}
}
.flexbutton {
	display: flex;
	align-content: center;
}

.addweb {
	background: #ffffff;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 16px;
	text-align: center;
	color: #19943c;
	cursor: pointer;
	width: 65px;
	height: 20px;
	border: 0.5px solid #57dac493;
	border-radius: 100px;
	margin-top: 24px;
	outline: none;

	&:disabled {
		cursor: not-allowed;
		background-color: #57dac493;
	}
}

.urlInput {
	outline: none;
	border-radius: 6px;
	width: 100%;
	height: 43px;
	padding-left: 10px;
	border: 1px solid gray;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
	padding-bottom: 8px;
	margin-top: 24px;
	border-bottom: 1px solid #e0e0e0;
}
