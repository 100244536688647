.accounts {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;
	width: fit-content;
	.accountInfo {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
	.accountEarnings {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #616161;
		margin-top: 4px;
	}
}

.radioSelection {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 12px;
}
.radioLabel {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
}

.radioSelection_one {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 25px;
	padding-bottom: 10px;
	border-bottom: 1px solid #e0e0e0;
	.radioLabel {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
}

.createLink {
	width: fit-content;
	height: 40px;
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	padding: 10px;
	cursor: pointer;
	.link {
		margin-right: 10px;
	}
}
