.balanceCard {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	margin: 0 20px;
}
.card {
	padding: 24px 10px 16px 16px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	height: 150px;
	width: 300px;
}

.viewWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.cardContent {
	padding-left: 12px;
	.cardCount {
	font-family: 'Sarabun';	
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
	.cardAmount {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
		color: #0a0a0a;
		padding-top: 8px;
	}
	.cardAmountNgn {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
		color: #0a0a0a;
		padding-top: 8px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		& span {
			font-family: 'Sarabun';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			display: flex;
			align-items: center;
			letter-spacing: -0.006em;
			color: #424242;
			padding-right: 4px;
		}
		.doubleZero {
			font-family: 'Sarabun';
			font-style: normal;
			font-weight: 500;
			align-self: flex-end;
			padding-bottom: 2px;
			font-size: 15px;
			line-height: 20px;
			display: flex;
			align-items: center;
			letter-spacing: -0.006em;
			color: #424242;
		}
	}

	.cardPercent {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 20px;
		.timeLine {
			font-family: 'Sarabun';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			display: flex;
			align-items: center;
			letter-spacing: -0.006em;
			color: #616161;
			padding-left: 8px;
		}
		.percentIncrease {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: fit-content;
			font-family: 'Sarabun';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			letter-spacing: -0.006em;
			color: #19943c;
			height: 24px;
			padding: 4px;
			& span {
				padding-left: 1.5px;
			}
		}

		.percentDecrease {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: fit-content;
			font-family: 'Sarabun';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			letter-spacing: -0.006em;
			color: #e00000;
			height: 24px;
			padding: 4px;
		}
	}
}

.view {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #016e20;
	cursor: pointer;
	margin-top: 50px;
	text-decoration: underline;
	text-decoration-color: #016e20;
	text-underline-offset: 5px;
}

.infoIcon {
	width: 20px;
	height: 20px;
}
