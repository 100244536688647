.signInBody {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	max-width: 80%;
	// border: 1px solid red;
	margin: 0 auto;
	// padding: 50px 0;
}

.logo {
	display: flex;
	justify-content: center;

	img {
		width: 150px;
	}
}

.businessForm {
	max-width: 500px !important;
	margin: 20px auto;
	display: flex;
	align-items: center;
	justify-content: center;

	&>form {
		display: flex;
		flex-direction: column;
		min-width: 300px;

		&>div {
			&:not(:first-child) {
				margin-top: 20px;
			}
		}
	}
}

.passwordInp {
	margin: 20px 0;
	position: relative;
	width: 100%;
}

.inputImg {
	cursor: pointer;
	position: absolute;
	width: 20px;
	right: 30px;
	top: 35px;
}

.signinForm {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	width: fit-content;
	margin-top: 40px;
	padding: 24px;



	.form {
		width: 100%;
	}

	.signIn {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
}

.signInButton {
	background: #19943c;
	border: none;
	// box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 100%;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 12.5px 0px;
	cursor: pointer;

	& span {
		padding-left: 10px;
	}
}

.forgotPassword {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #19943c;
	margin-top: 20px;
	cursor: pointer;
}

.newAccount {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin-top: 30px;

	.noAccount {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #424242;
	}

	.create {
		background: #19943c;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		height: 40px;
		padding: 5px 10px;
		margin-left: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		cursor: pointer;

		& span {
			padding-left: 10px;
		}
	}
}

.sidebarFooter {
	position: fixed;
	bottom: 0;
	left: 0;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: -0.006em;
	color: #424242;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
}

@media only screen and (max-width: 1200px) and (min-width: 601px) {
	.signinForm {
		width: 80vw;
	}
}

@media (max-width: 600px) {
	.signinForm {
		width: 80vw;
	}

	.newAccount {
		display: block;
		text-align: center;
	}
}