// .signInBody {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	margin-top: 15px;
// 	flex-direction: column;
// 	height: 100vh;
// }

// .logo {
// 	img {
// 		width: 150px;
// 	}
// }

// .signinForm {
// 	background: #ffffff;
// 	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
// 	border-radius: 12px;
// 	width: 507px;
// 	margin-top: 40px;
// 	padding: 24px;

// 	.businessForm {
// 		width: 100% !important;
// 		margin-top: 20px;
// 		margin-right: auto;

// 		&>form {
// 			display: flex;
// 			flex-direction: column;

// 			&>div {
// 				&:not(:first-child) {
// 					margin-top: 20px;
// 				}
// 			}
// 		}
// 	}

// 	.form {
// 		width: 100%;
// 	}

// 	.signIn {
// 		font-family: 'Sarabun';
// 		font-style: normal;
// 		font-weight: 600;
// 		font-size: 18px;
// 		line-height: 24px;
// 		letter-spacing: -0.011em;
// 		color: #0a0a0a;
// 	}
// }

// .signInButton {
// 	background: #19943c;
// 	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
// 	border-radius: 8px;
// 	width: 100%;
// 	font-family: 'Sarabun';
// 	font-style: normal;
// 	font-weight: 600;
// 	font-size: 16px;
// 	line-height: 24px;
// 	text-align: center;
// 	letter-spacing: -0.011em;
// 	color: #ffffff;
// 	padding: 12.5px 0px;
// 	cursor: pointer;
// 	outline: none;
// 	border: none;
// 	margin-top: 24px;

// 	& span {
// 		padding-left: 10px;
// 	}

// 	&:disabled {
// 		background-color: #969696;
// 	}
// }

// .forgotPassword {
// 	font-family: 'Sarabun';
// 	font-style: normal;
// 	font-weight: 600;
// 	font-size: 16px;
// 	line-height: 24px;
// 	text-align: center;
// 	letter-spacing: -0.011em;
// 	color: #19943c;
// 	margin-top: 20px;
// 	cursor: pointer;
// }

// .newAccount {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	flex-direction: row;
// 	margin-top: 30px;

// 	.noAccount {
// 		font-family: 'Sarabun';
// 		font-style: normal;
// 		font-weight: 600;
// 		font-size: 16px;
// 		line-height: 24px;
// 		text-align: center;
// 		letter-spacing: -0.011em;
// 		color: #424242;
// 	}

// 	.create {
// 		background: #19943c;
// 		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
// 		border-radius: 8px;
// 		width: 187px;
// 		height: 40px;
// 		margin-left: 12px;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		font-family: 'Sarabun';
// 		font-style: normal;
// 		font-weight: 600;
// 		font-size: 16px;
// 		line-height: 24px;
// 		text-align: center;
// 		letter-spacing: -0.011em;
// 		color: #ffffff;
// 		cursor: pointer;

// 		& span {
// 			padding-left: 10px;
// 		}
// 	}
// }

// .sidebarFooter {
// 	position: fixed;
// 	bottom: 0;
// 	left: 0;
// 	font-family: 'Sarabun';
// 	font-style: normal;
// 	font-weight: 400;
// 	font-size: 14px;
// 	line-height: 20px;
// 	display: flex;
// 	align-items: center;
// 	letter-spacing: -0.006em;
// 	color: #424242;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// 	padding: 16px;
// }

// @media only screen and (max-width: 1200px) and (min-width: 601px) {
// 	.signinForm {
// 		width: 80vw;
// 	}
// }

// @media (max-width: 600px) {
// 	.signinForm {
// 		width: 80vw;
// 	}

// 	.newAccount {
// 		display: block;
// 		text-align: center;
// 	}
// }


//NEW DESIGNS

.signWrapper {
	display: flex;
	height: 100vh;
}

.leftwrapper {
	flex: 1;
	padding: 30px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	/* Firefox */
}

.leftwrapper::-webkit-scrollbar {
	display: none;
}

.rightwrapper {
	flex: 1;
}

.rightwrapper_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: left;
	object-fit: cover;
}

.image {
	margin-bottom: 10px;
	img {
		width: 150px;
	}
}

.formwrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 106px;
}

.form_heading {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #272501;
	margin-bottom: 10px;
}

.form_p {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #858481;
}

.businessForm {
	padding: 0 140px;
	width: 100%;
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.signInButton {
	// max-width: 441px;
	width: 100%;
	height: 56px;
	background: #19943c;
	border-radius: 4px;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 4px;
	cursor: pointer;
	border: none;
	margin: 0 auto;
	margin-top: 36px;
}

.signInButton:disabled {
	opacity: .5;
}

.forgetPassword {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	color: #272501;
}

.createuser {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #272501;
	margin-top: 35px;
}

.forgetPasswordspan {
	color: #19943c;
	cursor: pointer;
}

.passwordInp {
	margin: 20px 0;
	position: relative;
	width: 100%;
}

.inputImg {
	cursor: pointer;
	position: absolute;
	width: 20px;
	right: 30px;
	top: 35px;
}

.fasignWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 0.5px solid rgb(200, 199, 199);
	border-radius: 8px;
	width: 700px;
	height: auto;
	margin: 70px auto;
}

.falogo {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	padding: 10px;
}

.faformwrapper {
	width: 80%;
}

.faform_p {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	color: #000000;
	margin: 30px 0px;
}

.fabusinessForm {
	width: 100%;
}

.faform {
	width: 100%;
}

.fasignInButton {
	width: 100%;
	height: 56px;
	background: #19943c;
	border-radius: 4px;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 4px;
	border: none;
	margin: 0 auto;
	margin-top: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.facreateuser {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #000000;
	margin: 30px 0;
}

@media only screen and (max-width: 1100px) {
	.signinForm {
		margin: 0 auto;
	}

	.rightwrapper {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	.signinForm {
		margin: 0 auto;
		// width: 350px;
	}

	.businessForm {
		padding: 0 40px;
	}
}

@media only screen and (max-width: 600px) {
	.businessForm {
		padding: 0 10px;
	}
}